.banner-box {
  width: 100%;
  background-image: url("/main/main-moverii-banner.webp");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 820px) {
  .banner-box {
    background-position-y: center;
  }
}

@media screen and (min-width: 1024px) {
  .banner-box {
    background-position-y: 80%;
  }
}

@media screen and (min-width: 1100px) {
  .banner-box {
    background-position-y: 70%;
  }
}

.img_sportat {
  display: block;
  width: 100%;
  height: 100%;
}

.search_container {
  background: #ffffff;
  border-radius: 20px;
  padding: 13px 19px;
  border: 1.5px solid #e5e7eb;
  width: 100%;
  margin-right: 20px;
}
.category_item {
  width: 100%;
  flex: 80%;
}

.img_category {
  padding-right: 17px;
}
.category_text {
  margin: 0;
}
.title_item {
  color: #959499;
  font-size: 14px;
  font-size: 400;
  text-align: center;
}
.select_item {
  font-weight: 600;
  font-size: 16px;
}
.search_category {
  background-color: #ff9f1c;
  padding: 14px;
  border-radius: 20px;
  cursor: pointer;
}
.icon_box {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
}
.box_toturial {
  display: flex;
  flex-direction: column;
  flex: 0 0 30%;
  align-items: center;
}
.des_item {
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  color: #212121;
}
.link_item {
  flex: 0 0 12%;
  display: flex;
  align-items: flex-end;
}
.title_item {
  font-weight: 700;
  margin: 15px 0px;
  font-size: 18px;
  color: #212121;
}

.discover_item_half {
  flex: 0 0 30%;
  margin: 0 20px 20px 0;
  position: relative;
  height: 250px;
}

.resizeAbleSliderImage {
  border-radius: 20px;
}
.resizeAbleSliderGradiant {
  position: absolute;
  top: 0;
  right: 0;

  bottom: 0px;
  left: 0;
  border-radius: 15px;
  z-index: 4;
  background: linear-gradient(
    360deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(33, 33, 33, 0) 50%
  );
}
.imageCategory {
  width: 387px;
  height: 250px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
}
.imageCategory img {
  margin: 0;
  padding: 0;
  width: 387px;
  object-fit: cover;
  border-radius: 20px;
}
.gradiantCategory {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 15px;
  z-index: 4;
  background: linear-gradient(360deg, #212121 0%, rgba(33, 33, 33, 0) 59.81%);
}
.ImageRightCategory {
  width: 387px;
  height: 520px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  z-index: 200;
}
.ImageRightCategory img {
  margin: 0;
  padding: 0;
  width: 387px;
  object-fit: cover;
  border-radius: 20px;
}
.box_category_item {
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 70%;
}
.detail_offer {
  position: absolute;
  left: 0;
  bottom: 20px;
  color: white;
  width: 100%;
  z-index: 39;
}
.img_full_discover {
  width: 100%;
  height: 520px;
  display: block;
  border-radius: 20px;
  object-fit: cover;
}

.title_discover_item {
  font-size: 10px;
  text-align: left;
}

@media (min-width: 420px) {
  .title_discover_item {
    font-size: 14px;
  }
}

@media (min-width: 650px) {
  .title_discover_item {
    font-size: 16px;
  }
}

.discover_item_full {
  flex: 0 0 30%;
  height: 521px;
  position: relative;
}
.destination_container {
  background: #fdd59e;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.experience_detail {
  flex: 0 0 48%;
  display: flex;
  flex-direction: column;
}
.video_exp_box {
  position: relative;
}
.play_exp_box {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.play_icon {
  width: 84px;
  height: 84px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .ds1 {
    position: absolute;
    top: -13px !important;
    right: -13px;
    z-index: -5;
  }
  .ds2 {
    position: absolute;
    bottom: -10px !important;
    left: -13px !important;
    z-index: -5;
  }
}
.ds1 {
  position: absolute;
  top: -17px;
  right: -13px;
  z-index: -5;
}
.ds2 {
  position: absolute;
  bottom: -20px;
  left: -20px;
  z-index: -5;
}
.detail_exp_item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 25px;
}
.txt_exp {
  flex: 0 0 94%;
}

.col_img {
  width: 375px;
}
.inp_container {
  margin-top: 160px;
}
.card_ppl {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(120, 123, 154, 0.15);
  border-radius: 20px;
  margin-right: 15px;
}
.detail_ppl {
  padding: 10px 15px;
}
.line_gray {
  width: 100%;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 15px 0;
}
.img_card_ppl {
  height: 200px;
  max-width: 100%;
  overflow: hidden;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.img_res {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.reviews_ppl {
  font-size: 10px;
  color: #959499;
}
.price_ppl {
  font-size: 12px;
  color: #959499;
}
.buld_price {
  font-size: 16px;
  font-weight: 600;
  color: #212121;
}
.pad_sm {
  padding-right: 3px;
}

.box_slider_ppl {
  width: 100%;
  margin: 20px auto;
}

.img_hol {
}
.detail_hol {
}
.bg_img_hold {
  position: absolute;
  top: 15%;
  right: -27px;
  z-index: -5;
  width: 110%;
  height: 70%;
  display: block;
  object-fit: cover;
  border-radius: 20px;
}
.title_hold {
  color: #212121;
  font-weight: 700;
  font-size: 25px;
}
.image_hold {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  border-radius: 20px;
}

/* #####################  TRAVELER #################### */
.travel_container {
  /*display: flex;*/
  /*justify-content: space-between;*/
  /*width: 85%;*/
  /*margin: 0 auto;*/
}

.card_travel {
  /*padding: 20px 30px;*/
  /*background: #ffffff;*/
  /*box-shadow: 0px 4px 25px rgba(18, 163, 186, 0.15);*/
  /*border-radius: 20px;*/
  /*flex: 0 0 30%;*/
  /*color: #212121;*/
}
.des_travel {
  height: 130px;
  overflow-y: hidden;
  width: 100%;
  /* white-space: nowrap; */
}
.travel_pos {
  /* position: absolute; */
  /* top: -212px; */
  width: 100%;
  display: flex;
  padding-bottom: 30px;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(
    0deg,
    rgb(225, 249, 252) 46%,
    rgb(255, 255, 255) 46%
  );
}
/* ########## Stories ############## */

.stores_container {
  background-image: url("/main/bg-blue.svg");
  background-size: contain;
  position: relative;
  padding: 10px 0 70px;
  /* margin-top: 410px; */
}
.stories_slider {
}
.title_detail_stories {
  font-weight: 700;
  font-size: 20px;
  color: #212121;
}
.detail_stories {
}
.latest_stories {
  padding-top: 35px;
  margin: 0 auto;
  width: 85%;
}
.btn_read_more {
  padding: 11px 46px;
  cursor: pointer;
  background: #ff9f1c;
  border-radius: 20px;
  color: white;
  border: none;
  outline: none;
}
.des_p {
  color: #212121;
  font-size: 16px;
  margin-bottom: 25px;
}
.img_stories {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0;
  margin: 0;
  object-fit: cover;
  object-position: center;
  transition: 0.2s;
  cursor: pointer;
}
.img_stories:hover {
  transform: scale(1.1);
}
.box_slide_stories {
  position: relative;
}

.newMoveri_container {
  margin: 0 auto;
  width: 100%;
}
.f_46 {
  flex: 0 0 46%;
}

.img_new2 {
  position: absolute;
  top: 30px;
  left: -20%;
  width: 250px;
  height: 183px;
}
.img_new3 {
  position: absolute;
  top: 300px;
  right: 20%;
  width: 170px;
  height: 132px;
}
